import React from 'react';
import cc from 'classcat';
import { Text } from '../Text';
import styles from './badge.module.sass';
import { IconSVG } from '../SpriteSVG';

/**
 * Плашка
 */
export const Badge = ({ icon, size, children, view, ...props }) => {
  const getIcon = iconName => {
    switch (iconName) {
      case 'eco':
        return <IconSVG name={'emoji/ecoEmoji'} width={18} height={16} />;
      case 'standart':
        return <IconSVG name={'emoji/standardEmoji'} width={18} height={16} />;
      case 'comfort':
        return <IconSVG name={'emoji/comfortEmoji'} width={18} height={16} />;
      case 'lux':
        return <IconSVG name={'emoji/luxEmoji'} width={18} height={16} />;
      case 'premium':
        return <IconSVG name={'emoji/premiumEmoji'} width={18} height={16} />;
      case 'fire':
        return <IconSVG name={'emoji/fireEmoji'} width={18} height={16} />;
      case 'reviews':
        return <IconSVG name={'emoji/reviews'} width={18} height={16} />;
      case 'desc':
        return <IconSVG name={'emoji/descEmoji'} width={18} height={16} />;
      case 'blackFriday':
        return <IconSVG name={'promo/whitePersent'} width={18} height={16} />;
      case 'present':
        return <IconSVG name={'emoji/present'} width={18} height={16} />;
      case 'season':
        return <IconSVG name={'promo/badgeSeason'} width={18} height={16} />;
      case 'tour':
        return <IconSVG name={'emoji/anchor'} width={18} height={16} />;
      case 'shoppingDay':
        return <IconSVG name={'emoji/shoppingDay'} width={18} height={16} />;
      case 'newYear':
        return <IconSVG name={'promo/snowflake'} width={18} height={16} />;
      default:
        return iconName;
    }
  };
  return (
    <div className={cc([styles.badge, styles[view], styles[size]])} {...props}>
      {icon && <div className={styles.badgeIcon}>{getIcon(icon)}</div>}
      <Text align="initial" transform="uppercase" variant="text-2">
        {children}
      </Text>
    </div>
  );
};

Badge.defaultProps = {
  size: 'md',
  icon: undefined,
};
