import React from 'react';
import { NextSeo } from 'next-seo';
import Router from 'next/router';
import { logger } from '../../lib/logger';
import { Page503 } from '../ErrorPage/503';
import { RootStoreProvider } from '../../providers/RootStoreProvider';
import { Layout } from '../Layout';
import { SearchStoreProvider } from '../../providers/SearchStoreProvider';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: props.hasError || false,
      errorInfo: props.errorInfo || null,
      typeEnv: typeof window === 'undefined' ? 'ssr' : 'client',
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorInfo: error,
      stacktrace: error.stack,
      line_number: error.lineNumber,
      message: error.message,
      error
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ 
      hasError: true,
      typeEnv: 'client'
    });

    try {
      const startTime = performance.now();
      
      logger({
        level: 'error',
        message: error.message || 'Unknown client error',
        additionalFields: {
          typeEnv: this?.state?.typeEnv || '',
          page: Router.pathname,
          params: Router.query,
          endpoint: Router.pathname,
          boundary: 'es',
          componentStack: errorInfo?.componentStack,
          duration: performance.now() - startTime,
          ...this.props.errorInfo
        },
        error: error.toString(),
        stacktrace: error.stack
      });

    } catch (loggingError) {
      console.error('Error during error logging:', {
        originalError: error,
        loggingError
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <SearchStoreProvider>
          <RootStoreProvider>
            <NextSeo title={'Ошибка 500: страница не доступна'} />
            <Layout
              isMobile={this.props.isMobile}
              isTablet={this.props.isTablet}
              isOutsPage
            >
              <Page503 error={this.state} />
            </Layout>
          </RootStoreProvider>
        </SearchStoreProvider>
      );
    }

    return this.props.children;
  }
}
