/* eslint-disable import/order */
/* eslint-disable no-dupe-class-members */
// @ts-nocheck
import { makeObservable, observable, action, computed, toJS } from 'mobx';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import memoizeOne from 'memoize-one';
import { SearchApi } from '@/api/SearchApi';
import { CruiseType } from '@/typings/cruise';
import { removeDuplicates } from '@/utils/removeDuplicates';
import { Search } from './Search.store';
import { IHydratedStore, IHydrationData } from '@/typings/types';
import { isClient } from '@/utils/isClient';
import { protectSetValue } from '@/utils/protectSetValue';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

type Sorts = {
  title: string;
  mode: string;
  type: string;
}[];

const toArray = array =>
  Array.isArray(array) ? array : Object.values(array || []);

export class MainResult extends Search implements IHydratedStore {
  api: any;
  data: any;
  extraCondition: string;
  sorts: Sorts;
  api;
  root;
  paramsFilters;
  departureCities;
  directions;
  directionsNames;
  days;
  minDate;
  maxDate;
  disabledDate;
  passengers;
  returnCruise;
  urlParams;
  bottomFilters;
  data;
  cards;
  extraShips;
  cruises;
  popularCards;
  popularPageCount;
  popularIsLoading;
  filterShips;
  filterDiscounts;
  setParamsFilters;
  updateFilters;
  removeBlankProp;
  departureCities;
  convertFiltersToUrl;
  shipsClasses;
  abortController: any;

  constructor(root, api) {
    super(root, api);

    this.api = new SearchApi();
    this.data = {};
    // this.type = CruiseType.RIVER;
    if (isClient) {
      this.abortController = new AbortController();
    }
    this.departureCities = [];
    this.directions = [];
    this.directionsNames = [];
    this.days = [];
    this.minDate = '';
    this.maxDate = '';
    this.disabledDate = [];
    this.passengers = {};
    this.returnCruise = false;
    this.urlParams = '';
    this.bottomFilters = {};
    this.data = {};
    // this.extraShips = '';
    this.popularCards = [];
    this.filterShips = {};
    this.filterDiscounts = [];
    this.shipsClasses = {};

    this.paramsFilters = {
      search_page: 1,
      selected_start_cities: JSON.stringify({}),
      selected_visit_cities: JSON.stringify({}),
      filter_directions: JSON.stringify([]),
      filter_ships: JSON.stringify({}),
      filter_dates: JSON.stringify({}),
      filter_length: JSON.stringify({}),
      filter_capacity: JSON.stringify({}),
      sort_mode: 'commission',
      sort_direction: 'DESC',
      sort_changed: 0,
      curSelecting: 'ships_filter',
      type: CruiseType.RIVER,
      return_cruise: 0,
      passengers: JSON.stringify({}),
      filt_month_year: 0,
    };

    makeObservable(this, {
      departureCities: observable,
      directions: observable,
      directionsNames: observable,
      days: observable,
      minDate: observable,
      maxDate: observable,
      disabledDate: observable,
      passengers: observable,
      returnCruise: observable,
      urlParams: observable,
      bottomFilters: observable,
      // extraShips: observable,
      filterShips: observable,
      filterDiscounts: observable,
      shipsClasses: observable,
      getShipByCategory: action,
      pickedShips: computed,
    });
    // makeObservable(this, {

    //   abortController: observable,
    // });
  }

  toArrayFromObjOfObj(obj = {}) {
    return Object.values(obj || [])
      .map(item =>
        item.county_name
          ? `${item.city_name}, ${item.county_name}`
          : item.city_name,
      )
      .filter(Boolean);
  }

  getDirectionById(id = [], dir = []) {
    return toArray(dir)
      .filter(idx => id.includes(idx.city_name))
      .map(item => item.cities)
      .flat()
      .map(item => item.city_name);
  }

  addTotalPlaces(passengers) {
    const totalPrice = passengers.adults + passengers.children;
    return { ...passengers, total_placed: totalPrice };
  }

  toObjFromArray(arr = []) {
    return arr && JSON.stringify(arr.reduce((a, v) => ({ ...a, [v]: v }), {}));
  }

  getMaxCapacity(cap = {}) {
    const { adults, years } = cap;

    const childrenFiveYearsLength = years.filter(y => y >= 5).length;

    const maxCap = childrenFiveYearsLength + adults;

    return maxCap > 0
      ? JSON.stringify({ [maxCap]: maxCap })
      : JSON.stringify({});
  }

  formatDates(dates = '') {
    this.clearFilters();
    const formatter = memoizeOne(d =>
      Object.values(JSON.parse(d)).map(i => dayjs(i)),
    );
    return formatter(dates);
  }

  getRangeDates(startDate = '', endDate = '') {
    const days = dayjs(endDate).diff(dayjs(startDate), 'day');
    return (
      days &&
      [...Array(days + 1).keys()].map(i =>
        dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'),
      )
    );
  }

  getFormatDate(date) {
    if (date) {
      if (
        !date[1] ||
        dayjs(date[0]).format('YYYY-MM-DD') ===
          dayjs(date[1]).format('YYYY-MM-DD')
      ) {
        return [dayjs(date[0]).format('YYYY-MM-DD')];
      }
      return this.getRangeDates(dayjs(date[0]), dayjs(date[1]));
    }
    return [];
  }

  getShipByCategory(id = [], ships = {}, params = {}) {
    const { type } = params;
    return toArray(type === 'include' ? pick(ships, id) : omit(ships, id))
      .map(idx => Object.keys(idx))
      .flat();
  }

  get pickedShips() {
    return this.getShipByCategory(this?.filters?.typeShip, this.filterShips, {
      type: 'include',
    });
  }

  get unPickedShips() {
    return this.getShipByCategory(this?.filters?.typeShip, this.filterShips, {
      type: 'exclude',
    });
  }

  setBottomFilters(filters) {
    this.clearFilters();
    this.bottomFilters = filters;
    this.paramsToUrl();
  }

  setParamsFilters() {
    const { type, departure, direction, duration, dates, passengers, goBack } =
      this.filters;
    // this.extraCondition
    // this.paramsFilters.extraCondition = this.extraCondition || 'new_main';

    this.paramsFilters.sort_mode =
      this.filters?.sort?.sort_mode ?? 'commission';

    this.paramsFilters.sort_direction =
      this.filters?.sort?.sort_direction ?? 'DESC';

    this.paramsFilters.selected_start_cities = this.toObjFromArray(departure);

    if (type !== CruiseType.SEA) {
      this.paramsFilters.selected_visit_cities = this.toObjFromArray(
        this.getDirectionById(direction, this.directions),
      );
    } else {
      // Это все надо будет пофиксить и убрать лишнее
      const getKeyByValue = (object, value) =>
        Object.keys(object).find(key => object[key] === value);

      const prepare = {};

      // eslint-disable-next-line guard-for-in
      for (const key in this.directions) {
        prepare[key] = this.directions[key].dir_name;
      }

      this.paramsFilters.filter_directions = this.toObjFromArray(
        direction.map(i => getKeyByValue(prepare, i)),
      );
    }

    if (type !== CruiseType.RIVER) {
      this.paramsFilters.selected_visit_cities = this.toObjFromArray(
        this.getDirectionById(direction, this.directions),
      );
    } else {
      // Это все надо будет пофиксить и убрать лишнее
      const getKeyByValue = (object, value) =>
        Object.keys(object).find(key => object[key] === value);

      const prepare = {};

      // eslint-disable-next-line guard-for-in
      for (const key in this.directions) {
        prepare[key] = this.directions[key].dir_name;
      }

      this.paramsFilters.filter_directions = this.toObjFromArray(
        direction.map(i => getKeyByValue(prepare, i)),
      );
    }

    this.paramsFilters.filter_dates = this.prepareDates(dates);

    this.paramsFilters.filter_length = duration;

    this.paramsFilters.passengers = JSON.stringify(
      this.addTotalPlaces(passengers),
    );

    this.paramsFilters.filter_ships = removeDuplicates([
      ...toArray(this.filters.ships),
      ...this.pickedShips,
    ]);
    // this.paramsFilters.selected_discounts = this.filters?.discounts;

    this.paramsFilters.filter_capacity = this.getMaxCapacity(
      this.filters.passengers,
    );

    this.paramsFilters.return_cruise = goBack ? '1' : '0';

    this.paramsFilters.type = type;
  }

  updateFilters() {
    const {
      filt_start_city,
      // *Для моря
      filt_directions,
      // *Реки
      filt_visit_directions,
      filt_length,
      filt_dates,
      return_cruise,
      filt_ships,
      filt_discounts,
      ship_class_data,
    } = this.data;

    // eslint-disable-next-line no-unused-vars
    const { type, direction } = this.filters;

    this.filterShips = protectSetValue(
      this.filterShips,
      filt_ships || this.filterShips,
    );

    this.departureCities = protectSetValue(
      this.departureCities,
      this.toArrayFromObjOfObj(filt_start_city),
    );

    // this.directions = toArray(filt_visit_directions);

    // this.directionsNames = this.directions.map(i => i.city_name);

    this.filterDiscounts = protectSetValue(
      this.filterDiscounts,
      filt_discounts,
    );

    this.shipsClasses = protectSetValue(
      this.shipsClasses,
      Object.values(
        pick(ship_class_data || {}, Object.keys(this.filterShips || [])) ?? [],
      ).sort((a, b) => (a.sort > b.sort ? 1 : -1)),
    );

    if (type === CruiseType.RIVER) {
      this.directions = protectSetValue(
        this.directions,
        toArray(filt_visit_directions),
      );

      this.directionsNames = protectSetValue(
        this.directionsNames,
        this.directions.map(i => i.city_name),
      );
    } else if (type === CruiseType.SEA) {
      this.directions = protectSetValue(this.directions, filt_directions);

      this.directionsNames = protectSetValue(
        this.directionsNames,
        toArray(this.directions).map(i => i.dir_name),
      );
    }

    this.days = protectSetValue(this.days, filt_length || {});

    this.minDate = protectSetValue(this.minDate, filt_dates?.today_date ?? '');

    this.disabledDate = protectSetValue(
      this.disabledDate,
      filt_dates?.disabled_dates
        ? this.formatDates(filt_dates?.disabled_dates)
        : [],
    );

    this.returnCruise = protectSetValue(this.returnCruise, return_cruise);

    // this.data = Object.values(data?.cruises?.cruises_data || []) ?? [];

    // this.cruises = [...this.cruises, ...this.data];
    // // Условия для того чтоб пагинация не ломала кол-во
    // if (data?.cruises?.amount) {
    //   this.amount = data.cruises.amount;
    // }
  }

  convertFiltersToUrl(filters) {
    return {
      start_cities: filters?.departure.join(',') ?? '',
      visit_cities: filters?.direction.join(',') ?? '',
      // sort_direction: filters?.sort?.sort_direction ?? '',
      // sort_mode: filters?.sort?.sort_mode ?? '',
      typeShip: filters?.typeShip?.join(',') ?? '',
      cruise_length: filters?.duration.join(',') ?? '',
      cruise_capacity: filters?.passengers?.adults ?? '',
      children_capacity: filters?.passengers?.children ?? '',
      years_capacity: filters?.passengers?.years.join(',') ?? '',
      return_cruise: filters?.goBack ? '1' : '0',
      type: filters?.type || '',
      cruise_dates: this.prepareDates(filters?.dates),
    };
  }
  defaultFilterParams() {
    this.paramsFilters = {
      search_page: 1,
      selected_start_cities: JSON.stringify({}),
      selected_visit_cities: JSON.stringify({}),
      filter_directions: JSON.stringify([]),
      filter_ships: JSON.stringify({}),
      filter_dates: JSON.stringify({}),
      filter_length: JSON.stringify({}),
      filter_capacity: JSON.stringify({}),
      // sort_mode: 'kr_date_start',
      // sort_direction: 'ASC',
      // sort_changed: 0,
      // curSelecting: 'ships_filter',
      // type: CruiseType.RIVER,
      // return_cruise: 0,
      // passengers: JSON.stringify({}),
      // filt_month_year: 0,
    };
  }
  get ships() {
    const classes = Object.keys(this.filterShips);
    const ships = Object.values(this.filterShips) ?? {};

    ships.splice(3, 4, ships[4], ships[3]);
    classes.splice(3, 4, classes[4], classes[3]);

    return ships
      .map((item, index) => ({
        class: classes[index],
        ships: Object.values(item ?? {}),
      }))
      .reverse();
  }

  prepareDates(dates) {
    const array = dates;

    if (array?.length === 1) {
      array.push(array[0]);
    }

    return Array.isArray(array)
      ? array
          .filter(date => !dayjs().isSameOrAfter(dayjs(date), 'day'))
          .map(i => dayjs(i).format('YYYY-MM-DD'))
          .join(',')
      : '';
  }

  hydrate(data: IHydrationData) {
    this.extraCondition = data?.popdir?.extraCondition;
    this.sorts = data?.popdir?.sorts;
    this.paramsFilters.type = data?.isSea ? CruiseType.SEA : CruiseType.RIVER;
    if (data?.searchStore?.cruises?.cruises_data) {
      this.cards = data.searchStore.cruises.cruises_data;
    }
  }
}
