// TODO: Рефакторинг
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import memoizeOne from 'memoize-one';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import Router from 'next/router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { CruiseType } from '../components/SearchCruises/SearchCruises';
import { removeDuplicates } from '../utils/removeDuplicates';
import { CreateParamsFilters } from '../utils/useParamsFilters';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const toArray = array =>
  Array.isArray(array) ? array : Object.values(array || []);

const prepareDates = dates => {
  const array = dates;

  if (array?.length === 1) {
    array.push(array[0]);
  }

  return Array.isArray(array)
    ? array.map(i => dayjs(i).format('YYYY-MM-DD')).join(',')
    : '';
};

export class SearchStore {
  api;
  amount;
  root;
  paramsFilters;
  departureCities;
  directions;
  directionsNames;
  days;
  minDate;
  disabledDate;
  passengers;
  returnCruise;
  urlParams;
  filters;
  bottomFilters;
  data;
  extraShips;
  cruises;
  page;
  paginatePage;
  popularCards;
  popularPageCount;
  popularIsLoading;
  filterShips;
  filterDiscounts;
  extraCondition;
  isLoading;
  useFirstLoad;
  cardList;

  constructor(root, api) {
    this.root = root;
    this.api = api;
    this.extraCondition = 'new_main';
    this.cruises = [];
    this.filterShips = [];
    this.extraShips = [];
    this.filterDiscounts = [];
    this.page = 1;
    this.paginatePage = 1;
    this.popularPageCount = 0;
    this.popularCards = [];
    this.isLoading = false;
    this.useFirstLoad = true;
    this.popularIsLoading = false;
    this.shipsClasses = [];
    this.paramsFilters = new CreateParamsFilters();
    this.amount = 0;
    makeAutoObservable(this);
  }

  async setPage() {
    this.page += 1;
    this.paramsFilters.search_page = this.page;
    await this.getList();
  }

  toArrayFromObjOfObj(obj = {}) {
    return Object.values(obj || [])
      .map(item => item.city_name)
      .filter(Boolean);
  }

  toObjFromArray(arr = []) {
    return arr && JSON.stringify(arr.reduce((a, v) => ({ ...a, [v]: v }), {}));
  }

  getDirectionById(id = [], dir = []) {
    return toArray(dir)
      .filter(idx => id.includes(idx.city_name))
      .map(item => item.cities)
      .flat()
      .map(item => item.city_name);
  }

  // Если нету айдишников класса в пиккед кают, удаляем из values.ships

  getRangeDates(startDate = '', endDate = '') {
    const days = dayjs(endDate).diff(dayjs(startDate), 'day');
    return (
      days &&
      [...Array(days + 1).keys()].map(i =>
        dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'),
      )
    );
  }

  getFormatDate(date) {
    if (date) {
      if (
        !date[1] ||
        dayjs(date[0]).format('YYYY-MM-DD') ===
          dayjs(date[1]).format('YYYY-MM-DD')
      ) {
        return [dayjs(date[0]).format('YYYY-MM-DD')];
      }
      return this.getRangeDates(dayjs(date[0]), dayjs(date[1]));
    }
    return [];
  }

  addTotalPlaces(passengers) {
    const totalPrice = passengers.adults + passengers.children;
    return { ...passengers, total_placed: totalPrice };
  }

  getShipByCategory(id = [], ships = {}, params = {}) {
    const { type } = params;
    return toArray(type === 'include' ? pick(ships, id) : omit(ships, id))
      .map(idx => Object.keys(idx))
      .flat();
  }

  get pickedShips() {
    return this.getShipByCategory(
      this?.bottomFilters?.typeShip,
      this.filterShips,
      { type: 'include' },
    );
  }

  get unPickedShips() {
    return this.getShipByCategory(
      this?.bottomFilters?.typeShip,
      this.filterShips,
      { type: 'exclude' },
    );
  }

  setExtraShips(ships) {
    this.extraShips = ships;
  }

  get allShips() {
    return removeDuplicates([...this.extraShips, ...this.pickedShips]);
  }

  setParamsFilters() {
    const { type, departure, direction, duration, dates, passengers, goBack } =
      this.filters;
    // this.extraCondition
    this.paramsFilters.extraCondition = this.extraCondition || 'new_main';
    this.paramsFilters.sort_mode =
      this.bottomFilters?.sort?.sort_mode ?? 'commission';

    this.paramsFilters.sort_direction =
      this.bottomFilters?.sort?.sort_direction ?? 'DESC';

    this.paramsFilters.selected_start_cities = this.toObjFromArray(departure);

    if (type !== CruiseType.Sea) {
      this.paramsFilters.selected_visit_cities = this.toObjFromArray(
        this.getDirectionById(direction, this.directions),
      );
    } else {
      // Это все надо будет пофиксить и убрать лишнее
      const getKeyByValue = (object, value) =>
        Object.keys(object).find(key => object[key] === value);

      const prepare = {};

      // eslint-disable-next-line guard-for-in
      for (const key in this.directions) {
        prepare[key] = this.directions[key].dir_name;
      }

      this.paramsFilters.filter_directions = this.toObjFromArray(
        direction.map(i => getKeyByValue(prepare, i)),
      );
    }

    this.paramsFilters.filter_length = this.toObjFromArray(duration);

    this.paramsFilters.filter_dates = this.toObjFromArray(
      this.getFormatDate(dates),
    );

    this.paramsFilters.passengers = JSON.stringify(
      this.addTotalPlaces(passengers),
    );

    this.paramsFilters.filter_ships = this.toObjFromArray(this.allShips);
    this.paramsFilters.selected_discounts = this.bottomFilters?.discounts;

    this.paramsFilters.filter_capacity = this.getMaxCapacity(
      this.filters.passengers,
    );

    this.paramsFilters.return_cruise = goBack ? '1' : '0';

    this.paramsFilters.type = type;
  }

  getMaxCapacity(cap = {}) {
    const { adults, years } = cap;

    const childrenFiveYearsLength = years.filter(y => y >= 5).length;

    const maxCap = childrenFiveYearsLength + adults;

    return maxCap > 0
      ? JSON.stringify({ [maxCap]: maxCap })
      : JSON.stringify({});
  }

  formatDates(dates = '') {
    const formatter = memoizeOne(d =>
      Object.values(JSON.parse(d)).map(i => dayjs(i)),
    );
    return formatter(dates);
  }

  setFilters(filters) {
    // this.clearFilters();
    this.filters = filters;
    this.paramsToUrl();
  }

  setBottomFilters(filters) {
    this.clearFilters();
    this.bottomFilters = filters;
    this.paramsToUrl();
  }

  convertFiltersToUrl(filters) {
    return {
      start_cities: filters?.departure.join(',') ?? '',
      visit_cities: filters?.direction.join(',') ?? '',
      // sort_direction: this.bottomFilters?.sort.sort_direction ?? '',
      // sort_mode: this.bottomFilters?.sort.sort_mode ?? '',
      cruise_length: filters?.duration.join(',') ?? '',
      cruise_capacity: filters?.passengers?.adults ?? '',
      children_capacity: filters?.passengers?.children ?? '',
      years_capacity: filters?.passengers?.years.join(',') ?? '',
      return_cruise: filters?.goBack ? '1' : '0',
      type: filters?.type !== CruiseType.Rivers ? filters?.type : '',
      cruise_dates: prepareDates(filters?.dates),
    };
  }

  get params() {
    return this.convertFiltersToUrl(this.filters);
  }

  removeBlankProp(obj) {
    return Object.entries(obj)
      .filter(([, v]) => v !== '')
      .filter(([, v]) => v !== 0)
      .filter(([, v]) => v !== '0')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  async paramsToUrl() {
    const { push, pathname, query } = Router;

    await push(
      {
        pathname,
        query: this.removeBlankProp({ ...query, ...this.params }),
      },
      undefined,
      { scroll: false, shallow: true },
    );
  }

  get maxDate() {
    if (!this.disabledDate || this.disabledDate.length === 0) {
      return null;
    }
    return this.disabledDate.reduce((max, currentDate) =>
      dayjs(max).isAfter(dayjs(currentDate)) ? max : currentDate,
    );
  }

  clearFilters() {
    this.cruises = [];
    this.data = [];
    this.page = 1;
    this.paramsFilters.search_page = 1;
  }

  updateFilters(data) {
    const {
      filt_start_city,
      // *Для моря
      filt_directions,
      // *Реки
      filt_visit_directions,
      filt_length,
      filt_dates,
      return_cruise,
      filt_ships,
      filt_discounts,
    } = data;

    if (
      filt_dates ||
      filt_length ||
      filt_start_city ||
      filt_visit_directions ||
      return_cruise
    ) {
      this.departureCities = this.toArrayFromObjOfObj(filt_start_city);

      this.directions = toArray(filt_visit_directions);

      this.directionsNames = this.directions.map(i => i.city_name);

      const { type } = this.filters;
      this.filterShips = filt_ships || {};

      this.filterDiscounts = filt_discounts;

      this.shipsClasses = Object.values(
        pick(
          data?.ship_class_data || {},
          Object.keys(this.filterShips || []),
        ) ?? [],
      );

      if (type === CruiseType.Rivers) {
        this.directions = toArray(filt_visit_directions);

        this.directionsNames = this.directions.map(i => i.city_name);
      } else if (type === CruiseType.Sea) {
        this.directions = filt_directions;

        this.directionsNames = toArray(this.directions).map(i => i.dir_name);
      }

      this.days = filt_length || {};

      this.minDate = filt_dates?.min_date ?? '';

      this.disabledDate = filt_dates.disabled_dates
        ? this.formatDates(filt_dates.disabled_dates)
        : [];

      this.returnCruise = return_cruise;

      // this.filterShips = [];
    }

    this.data = Object.values(data?.cruises?.cruises_data || []) ?? [];

    this.cruises = [...this.cruises, ...this.data];
    // Условия для того чтоб пагинация не ломала кол-во
    if (data?.cruises?.amount) {
      this.amount = data.cruises.amount;
    }
  }

  async getList() {
    const { api, filters } = this;

    const methods = {
      [CruiseType.Rivers]: 'getList',
      [CruiseType.Sea]: 'getSeaList',
    };

    const currentMethod = methods[filters.type];
    this.isLoading = true;
    try {
      this.isLoading = true;
      const res = await api[currentMethod](this.paramsFilters);
      this.setParamsFilters(res.data);
      this.updateFilters(res.data);
      runInAction(() => {
        this.isLoading = true;
        this.useFirstLoad = false;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.isLoading = false;
        this.useFirstLoad = false;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.useFirstLoad = false;
      });
    }
  }

  async getPopularCruises(page) {
    const { api } = this;

    this.paginatePage = page?.currentPage ?? 1;
    const popularApi = new CreateParamsFilters('popular_cruises');
    const params = popularApi.paramsFilters;
    params.search_page = this.paginatePage;

    const toArrayCards = array =>
      Array.isArray(array) ? array : Object.values(array);

    try {
      this.popularIsLoading = true;
      const res = await api.getList(params);
      this.popularCards = toArrayCards(res.data.cruises.cruises_data);
      this.popularPageCount = res.data.cruises.page_count;
    } catch (error) {
      console.error(error);
      this.popularIsLoading = false;
    } finally {
      this.popularIsLoading = false;
    }
  }

  // eslint-disable-next-line consistent-return
  async getPopularCards(page) {
    const { api } = this;
    try {
      await api.getPopularCards(page).then(res => {
        runInAction(() => {
          this.popularCards = res?.data?.cruises?.cruises_data;
          this.popularPageCount = res?.data?.cruises?.page_count;
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  // TODO: Переделать, чтобы попдир передавался в оберке searchStore: { popdir:... }
  hydrate({ popdir, ...otherKeys }) {
    if (popdir) {
      this.extraCondition = popdir?.extraCondition;
    }
    Object.entries(otherKeys).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}
