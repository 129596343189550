// проверка на начало и конец НГ
export const isNewYear = () => {
  const date = new Date();

  const startDatePromotion = new Date(1733691600000);
  const endDatePromotion = new Date(1736715600000);
  // console.log('startDatePromotion', startDatePromotion);
  // console.log('endDatePromotion', endDatePromotion);

  return date >= startDatePromotion && date <= endDatePromotion;
};
