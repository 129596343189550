import { CruiseType } from '../components/SearchCruises/SearchCruises';

class CreateParamsFilters {
  constructor(extraCondition = 'new_main') {
    this.paramsFilters = {
      search_page: 1,
      selected_start_cities: JSON.stringify({}),
      selected_visit_cities: JSON.stringify({}),
      filter_directions: JSON.stringify([]),
      filter_ships: JSON.stringify([]),
      filter_dates: JSON.stringify({}),
      filter_length: JSON.stringify({}),
      filter_capacity: JSON.stringify({}),
      sort_mode: 'commission',
      sort_direction: 'DESC',
      sort_changed: 0,
      curSelecting: 'ships_filter',
      extraCondition,
      type: CruiseType.Rivers,
      return_cruise: 0,
      passengers: JSON.stringify({}),
      filt_month_year: 0,
    };
  }
}

export { CreateParamsFilters };
