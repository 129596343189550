import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './newYearFooter.module.sass';
import { useAuthStore } from '../../../../providers/RootStoreProvider';
import { Text, Anchor } from '../../../Text';
import { Icon } from '../../../Icon';
import { YaAndGaFooter } from '../../../../lib/YaAndGaHelper';
import { Subscribe } from '../../../MainPage/Subscribe';
import { IconSVG } from '../../../SpriteSVG';
import { SubFormNewYear } from '../../../SubFormNewYear/SubFormNewYear';

const otherPages = [
  { href: '/about', title: 'О компании' },
  { href: 'https://kruiz.online/blog', title: 'Блог' },
  { href: '/politic', title: 'Пользовательское соглашение' },
  { href: '/media', title: 'СМИ о нас' },
  { href: '/reviews', title: 'Отзывы' },
  { href: '/rent', title: 'Аренда теплохода' },
  { href: '/discount', title: 'Скидки и акции' },
  { href: '/faq', title: 'Вопросы и ответы' },
  { href: '/vendors', title: 'Наши партнеры' },
];

const social = [
  {
    href: 'https://wa.me/79397100589',
    metriks: 'click_whatsup_footer',
    icons: 'social/wa_mono',
    title: 'whatsapp',
  },
  {
    href: 'https://t.me/kruizonline',
    // metriks: '',
    icons: 'social/tg_mono',
    title: 'telegram',
  },
  {
    href: 'https://vk.com/kruiz.online',
    metriks: 'click_vk_footer',
    icons: 'social/vk_mono',
    title: 'Вконтакте',
  },
  {
    href: 'https://ok.ru/group/52494082834611',
    // metriks: '',
    icons: 'social/ok_mono',
    title: 'ok',
  },
  {
    href: 'https://zen.yandex.ru/id/5cf7c5fd92015300af0beb57',
    // metriks: '',
    icons: 'social/dzen_mono',
    title: 'dzen.yandex',
  },
];

export const NewYearFooter = observer(
  ({ isMobile, withoutForm, isContact, isStep, emailMetrik, user }) => {
    const store = useAuthStore();
    return (
      <footer className={styles.wrapper}>
        {isContact ? (
          <div className={styles.subBlock}>
            <Subscribe user={user} />
          </div>
        ) : (
          <>
            {isMobile ? (
              <div>
                {!withoutForm && !isStep && (
                  <SubFormNewYear
                    isMobile={isMobile}
                    emailMetrik={emailMetrik}
                    user={user}
                  />
                )}
              </div>
            ) : (
              <div className="container">
                {!withoutForm && !isStep && (
                  <SubFormNewYear
                    isMobile={isMobile}
                    emailMetrik={emailMetrik}
                    user={user}
                  />
                )}
              </div>
            )}
          </>
        )}
        <div className={styles.innerWrapper}>
          {!isStep && !store.isB2bMode && (
            <div className="container">
              <div className={styles.otherPages}>
                {otherPages.map(i => (
                  <div className={styles.footerLI}>
                    <Anchor href={i.href} className={styles.text}>
                      {i.title}
                    </Anchor>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!store.isB2bMode && (
            <div className="container">
              <div className={styles.socialWrapper}>
                <div className={styles.number}>
                  <div className="header-phone-big">
                    <a
                      href="tel:84958775834"
                      onClick={() => {
                        YaAndGaFooter('click_phone_footer');
                      }}
                      className={styles.text}
                    >
                      +7 495 877-58-34
                    </a>
                  </div>
                  <div className={styles.info}>
                    <Text variant="textBF">
                      ежедневно с 8:00 до 21:00 по МСК
                    </Text>
                  </div>
                </div>
                <div className={styles.footerSocial}>
                  <div className={styles.social}>
                    {social.map(i => (
                      <a
                        href={i.href}
                        target="_blanck"
                        onClick={() => {
                          YaAndGaFooter(i.metriks);
                        }}
                      >
                        <Icon
                          icon={
                            <IconSVG
                              name={i.icons}
                              width={isMobile ? 40 : 32}
                              height={isMobile ? 40 : 32}
                            />
                          }
                          title={i.title}
                        />
                      </a>
                    ))}
                    {!isMobile && (
                      <Anchor
                        className={styles.blogCompany}
                        weight="800"
                        href="https://kruiz.online/blog"
                        variant="linkAsbuttonMore"
                        onClick={() => {
                          YaAndGaFooter('click_blog_footer2');
                        }}
                      >
                        Блог
                      </Anchor>
                    )}
                  </div>
                </div>
                {isMobile && (
                  <div>
                    <Anchor
                      className={styles.blogCompany}
                      weight="800"
                      href="https://kruiz.online/blog"
                      variant="blogNewYear"
                      // style={{ padding: '6px 12px' }}
                      onClick={() => {
                        YaAndGaFooter('click_blog_footer2');
                      }}
                    >
                      Блог
                    </Anchor>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="container">
            <div className={styles.paymentMethods}>
              <a
                className={styles.paymentMethodTinkoff}
                href="https://www.tbank.ru/"
                rel="nofollow noreferrer"
              >
                <Icon
                  leftGap={10}
                  icon={
                    <IconSVG name={'payment/tbankNY'} width={115} height={48} />
                  }
                  title="ТБанк"
                />
              </a>
              <a
                className={styles.paymentMethodSbp}
                href="https://sbp.nspk.ru/"
                rel="nofollow noreferrer"
              >
                <Icon
                  leftGap={10}
                  icon={
                    <IconSVG name={'payment/sbpNY'} width={102} height={48} />
                  }
                  title="СБП"
                />
              </a>
            </div>
          </div>
          {isMobile ? (
            <div className="container">
              <div className={styles.footerCompany}>
                <Text className={styles.text}>
                  © 2016-{new Date().getFullYear()}, ООО "Круиз.онлайн", ИНН
                  6315008371, ОГРН 1166313053048 443001, Самара, ул.
                  Галактионовская 157, этаж 12 129090, Торгово-офисный центр
                  GARDEN MIR, проспект Мира, д. 40, 8 этаж, офис 804, Мещанский
                  район, Москва
                </Text>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className={styles.footerCompany}>
                <Text className={styles.text}>
                  © 2016-{new Date().getFullYear()}, ООО "Круиз.онлайн", ИНН
                  6315008371, ОГРН 1166313053048 <br />
                  443001, Самара, ул. Галактионовская 157, этаж 12
                  <br />
                  129090, Торгово-офисный центр GARDEN MIR, проспект Мира, д.
                  40, 8 этаж, офис 804, Мещанский район, Москва
                </Text>
              </div>
            </div>
          )}
        </div>
      </footer>
    );
  },
);
