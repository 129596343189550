import React from 'react';
import { Else, If, Then } from 'react-if';
import { Form, Formik } from 'formik';
import nookies from 'nookies';
import * as Yup from 'yup';
import styles from './subFormNewYear.module.sass';
import { Text } from '../Text';
import { oldApi } from '../../lib/api';
import { Input } from '../Input';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

const subscription = Yup.object().shape({
  email: Yup.string()
    .email('Проверьте правильность введенных данных')
    .trim()
    .required('Обязательное поле'),
  name: Yup.string().trim().required('Введите имя'),
});

export const SubFormNewYear = ({ isMobile, user }) => (
  <>
    <div className={styles.wrapper}>
      <div>
        <Formik
          validationSchema={subscription}
          initialValues={{
            name: '',
            email: '',
            success: false,
          }}
          onSubmit={async ({ name, email }, { resetForm, setFieldValue }) => {
            try {
              await oldApi.post('/User/subscribe_mailbox', {
                MAIL_BOOK_ID: 69,
                user: name,
                email,
                user_id: user?.id || null,
                ym_cid: !user?.isManager
                  ? nookies.get(null)?._ym_uid || null
                  : null,
              });
              resetForm();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log(e);
            } finally {
              setFieldValue('success', true);
            }
          }}
        >
          {({ values, isSubmitting }) => (
            <If condition={!values.success}>
              <Then>
                <Form>
                  <div className={styles.form}>
                    <div className={styles.block}>
                      {isMobile ? (
                        <div className={styles.wrapperHeader}>
                          Получайте новости <br /> и акции первыми!
                        </div>
                      ) : (
                        <div className={styles.wrapperHeader}>
                          Получайте новости и акции первыми!
                        </div>
                      )}
                      {isMobile ? (
                        <div className={styles.wrapperDescription}>
                          Подпишитесь на рассылку, чтобы первыми узнавать <br />{' '}
                          о скидках и специальных предложениях
                        </div>
                      ) : (
                        <div className={styles.wrapperDescription}>
                          Подпишитесь на рассылку, чтобы первыми узнавать о
                          скидках <br />и специальных предложениях
                        </div>
                      )}
                      <div className={styles.blockForm}>
                        <div>
                          <Input placeholder="Имя" id={'name'} label="Имя" />
                        </div>
                        <div>
                          <Input label="Почта" id="email" placeholder="Почта" />
                        </div>
                        <div>
                          <Button
                            type="submit"
                            size={'podpis-lg'}
                            fullWidth
                            disabled={isSubmitting}
                            className={styles.submitButton}
                            // onClick={() => window.ym(35441960, 'reachGoal', 'bf')}
                          >
                            Отправить
                          </Button>
                        </div>
                      </div>
                      <div className={styles.agreement}>
                        Нажимая «Отправить» вы принимаете{' '}
                        <a className={styles.politic} href="/politic">
                          Пользовательское соглашение
                        </a>{' '}
                        и даёте согласие на обработку ваших данных{' '}
                      </div>
                    </div>
                    {!isMobile && (
                      <div>
                        <img
                          alt=""
                          src="/newYear/christmasTree.png"
                          className={styles.image}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              </Then>
              <Else>
                <div className={styles.successForm}>
                  <div className={styles.success}>
                    <div className={styles.successContent}>
                      <div className={styles.icon}>
                        <Icon
                          icon={
                            <IconSVG
                              name={'common/subscribe-success-blue'}
                              width={78}
                              height={78}
                            />
                          }
                        />
                      </div>
                      <Text variant="heading-3" topGap={16}>
                        Спасибо за подписку!
                      </Text>
                      {isMobile ? (
                        <p className={styles.text}>
                          Мы будем присылать вам <br /> лучшие предложения и
                          скидки
                        </p>
                      ) : (
                        <p className={styles.text}>
                          Мы будем присылать вам лучшие предложения и скидки
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Else>
            </If>
          )}
        </Formik>
      </div>
    </div>
    {/* <div className={styles.footerButton}>
      <Anchor
        variant={'linkDirections'}
        href={'https://kruiz.online/files/bf.pdf'}
        target={'_blank'}
      >
        Правила и условия акции
      </Anchor>
    </div> */}
  </>
);
