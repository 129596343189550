import { makeAutoObservable, runInAction } from 'mobx';
import Router from 'next/router';
import uniqBy from 'lodash/uniqBy';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { makeAliasPlaces } from '../utils/makeAliasPlaces';
import { formatter } from '../utils/formatter';
import { isClient } from '../utils/isClient';
import { translit } from '../utils/translit';

const toArray = array => (!Array.isArray(array) ? array : Object.values(array));
const isBrowser = typeof window !== 'undefined';
export class CruizStore {
  root;
  api;
  id;
  agencyRate;
  activeCabin; // Активная каюта
  toggledModal;
  mobileCabins; // Мобильная модалка. Для мобильного списка, карточки уже готовые приходят с бека только нужно подшаманить
  rawFreeCabins;
  freeCabins; // Свободные каюты
  filters; // Фильтры. Объект массивов. отдает Formik ввиде объекта массивов
  activeTab; // Список или Схема
  cauteInfo;
  cabin2cat;
  agePassenger;
  pickAge;
  currentFood;
  currentAge;
  selectedCabins;
  discountsInfo;
  promocode;
  isActivated;
  promoStatus;
  percent;
  isShowTooltip;
  dataTooltip;
  fio = null;
  phone = null;
  bitrixId = null;
  favoriteStore;
  reservedCabins;
  ownerId;
  lastPassenger;
  specialOffer;
  specialOfferValue;
  cauteInfoStorage;
  cruiseStorage;
  selectedCabinsStorage;
  amountDiscount;
  percentDiscount;
  installment;
  installmentPlan;
  error = null;

  constructor(root, api, favorites) {
    this.root = root;
    this.api = api;
    this.isActivated = false;
    this.freeCabins = [];
    this.toggledModal = false;
    this.activeTab = 'scheme';
    this.isShowTooltip = false;
    this.selectedCabins = {};
    this.selectedTariff = null;
    this.lastPassenger = false;
    this.filters = {
      spaciousness: [],
      deck: [],
      cost: [],
      class: [],
    };
    this.favoriteStore = favorites;
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CruizStore',
      storage: isBrowser ? window.localStorage : undefined,
      properties: [
        'selectedCabinsStorage',
        'fio',
        'phone',
        'bitrixId',
        'cruiseStorage',
        'amountDiscount',
        'percentDiscount',
        'installmentPlan',
      ],
    });
  }

  stopStore() {
    stopPersisting(this);
  }

  setActiveCabin(cabin) {
    this.activeCabin = cabin;
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  clearActiveCabin() {
    this.activeCabin = '';
  }

  initFreeCabins() {
    this.freeCabins = this.rawFreeCabins;
  }

  // Выбранная каюта
  // Беру по номеру каюты и сопоставлению по id каюты
  get cabinData() {
    return (
      this.freeCabins &&
      Object.values(this.freeCabins).find(
        x => x.cabin_number === this.activeCabin,
      )
    );
  }

  // Фотки, описание, список что есть в каюте
  get commonData() {
    const data = this.cauteInfo?.[this.cabin2cat?.[this.activeCabin]] ?? {};
    const description = data.k_description && data.k_description.split('.')[0];
    const features = data.k_description && data.k_description.split('.')[1];

    return {
      photos: data.cabin_fotos,
      description,
      features,
    };
  }

  get featuresModal() {
    const classOptinos = uniqBy(
      this.cauteInfo &&
        Object.values(this.cauteInfo).map(i => ({
          class: i.k_type_name,
          description: i.k_description,
          caption: i.k_caption,
        })),
      'description',
    ).filter(i => i.description);

    return {
      classReady: classOptinos,
    };
  }

  // Делегированием прохожусь достаю активную каюту
  getCabinNumber(e, params = {}) {
    const { scheme } = params;

    if (!scheme) {
      // Если список, то дата-атрибутми
      this.setActiveCabin(e.target.getAttribute('data-cabin'));
    } else {
      // Если схема, ищу на схемы каюты с селектором CautaClick и беру их чилдрен(номер каюты)
      const clickToCabin = e.target?.className?.animVal?.includes('CautaClick');

      // TODO: Вынести toLowerCase в  аргументфункции translit. Чтобы было так - translit(e.target.innerHTML, { toLowerCase: true })
      const translitActiveCabin = translit(e.target.innerHTML).toLowerCase();
      if (clickToCabin) {
        this.setActiveCabin(translitActiveCabin);
      }
    }
    if (!this.hasPickedCabin) {
      this.defaultPassengerTypeSelected();
    }

    if (this.filteredNumberCabins) {
      Router.push(
        {
          pathname: `/cruise/${Router.query.id}`,
          query: { cabin: this.activeCabin },
        },
        undefined,
        { scroll: false, shallow: true },
      );
      this.setToggledModal(true);
    }
    return null;
  }

  setClearUrl() {
    Router.push(
      {
        pathname: `/cruise/${Router.query.id}`,
      },
      undefined,
      { scroll: false, shallow: true },
    );
  }

  setToggledModal(toggled) {
    this.lastPassenger =
      this.freePlaces?.filter(place => place.place_selected === 1).length === 1;
    this.toggledModal = toggled;
    if (!this.toggledModal) {
      // Router.push(
      //   {
      //     pathname: `/cruise/${Router.query.id}`,
      //     query: {},
      //   },
      //   undefined,
      //   {scroll: false, shallow: true},
      // );
      this.clearActiveCabin();
    }
  }

  // Мобильные карточки приходят по разному, если одна каюта на палубе, то объект, а если несколько то массив объектов
  // Сделал всегда массив объектов
  get mobileListCabin() {
    const objectToArrayCabin = obj =>
      Object.keys(obj).reduce(
        (acum, key) => [
          ...acum,
          {
            class_name: obj[key].class_name,
            cabins: obj[key].cabins,
          },
        ],
        [],
      );

    // Сразу не приходит цена, добавил ее по ключу из свободных кают
    const addPriceToCabin = cabin =>
      cabin.map(c => {
        const cab = Object.values(c.cabins);
        const price = cab.map(
          p => this.freeCabins[String(p.cabin_num)]?.cabin_min_price,
        );
        return {
          class_name: c.class_name,
          cabins: cab.map((p, key) => ({
            ...p,
            price: price[key],
            alias: makeAliasPlaces(p.main_places, p.extra_places),
          })),
        };
      });

    const _mobileListCabin =
      this.mobileCabins &&
      this.mobileCabins.reduce((acum, element) => {
        const { deck_name, cabin_classes } = element;

        const cabinClasses = !Array.isArray(cabin_classes)
          ? objectToArrayCabin(cabin_classes)
          : cabin_classes;

        return [
          ...acum,
          { deck_name, cabin_classes: addPriceToCabin(cabinClasses) },
        ];
      }, []);

    return _mobileListCabin;
  }

  get filteredMobileListCabin() {
    return this.mobileListCabin;
  }

  setFiltersCabins(filter) {
    this.filters = filter;
  }

  // фильтрация кают по объекту, который возвращает формик
  get filteredCabins() {
    const getValue = value =>
      typeof value === 'string' ? value.toUpperCase() : value;

    const filterPlainArray = (array, filters) => {
      const filterKeys = Object.keys(filters);
      return array.filter(item =>
        filterKeys.every(key => {
          if (!filters[key].length) return true;
          return filters[key].find(
            filter => getValue(filter) === getValue(item[key]),
          );
        }),
      );
    };

    const filterPlace = (array, filters) => {
      const filterKeys = Object.keys(filters);
      return array.filter(item =>
        filterKeys.every(key => {
          if (!filters[key].length) return true;
          return filters[key].find(filter => getValue(filter) <= item[key]);
        }),
      );
    };

    const _filters = {
      cabin_min_price: this.filters.cost || [],
      // free_places: this.filters.spaciousness || [],
      deckName: this.filters.deck || [],
      category: this.filters.class || [],
    };

    const _filtersPlace = {
      free_places: this.filters.spaciousness || [],
    };
    const filterResult =
      this.filters.spaciousness?.length !== 0
        ? filterPlace(Object.values(this.freeCabins), _filtersPlace)
        : filterPlainArray(Object.values(this.freeCabins), _filters);
    return this.freeCabins && filterResult;
  }

  // Отфильтрованные номера кабин
  get filteredNumberCabins() {
    return this.filteredCabins
      .map(i => i.cabin_number)
      .includes(this.activeCabin);
  }

  // Динамические селекты
  get optionsCabinFilters() {
    const countPlaces = [1, 2, 3, 4];
    const spaciousnessOptinos = uniqBy(
      this.freeCabins &&
        countPlaces.map(i => ({
          value: i,
          label: makeAliasPlaces(i),
        })),
      'value',
    )
      .filter(i => i.value)
      .sort((a, b) => a.value - b.value);

    const costOptinos = uniqBy(
      this.freeCabins &&
        Object.values(this.freeCabins).map(i => ({
          value: i.cabin_min_price,
          label: formatter(i.cabin_min_price),
        })),
      'value',
    )
      .filter(i => i.value)
      .sort((a, b) => a.value - b.value);
    const deckOptinos = uniqBy(
      this.freeCabins &&
        Object.values(this.freeCabins)
          .filter(i => i.deckName)
          .map(i => ({ value: i.deckName, label: i.deckName.split(' ')[0] })),
      'value',
    );

    const categoryOptinos = uniqBy(
      this.freeCabins &&
        Object.values(this.freeCabins).map(i => ({
          value: i.category,
          label: i.category,
        })),
      'value',
    ).filter(i => i.value);

    return { spaciousnessOptinos, costOptinos, deckOptinos, categoryOptinos };
  }

  // Динамические селекты списка и мобилки
  get mobileOptionsFilters() {
    let spaciousnessMobileOptinos = [];
    let costMobileOptinos = [];
    let deckMobileOptinos = [];
    let categoryMobileOptinos = [];

    const list = Object.values(this.mobileListCabin);

    // Возможно необходимо поменять элементы местами
    const deckNames = ['Нижняя', 'Главная', 'Средняя', 'Шлюпочная'];

    for (let i = 0; i < list.length; i++) {
      const { cabin_classes, deck_name } = list[i];

      if (typeof +deck_name === 'number') {
        const name = deckNames[deck_name];
        deckMobileOptinos.push({
          value: name,
          label: name,
        });
      } else if (typeof deck_name === 'string') {
        deckMobileOptinos.push({
          value: deck_name,
          label: deck_name,
        });
      }

      for (let j = 0; j < cabin_classes.length; j++) {
        const { class_name, cabins } = cabin_classes[j];

        if (class_name !== '') {
          categoryMobileOptinos.push({
            value: class_name,
            label: class_name,
          });
        }

        for (let k = 0; k < cabins.length; k++) {
          const { main_places, price } = cabins[k];

          spaciousnessMobileOptinos.push({
            value: main_places,
            label: makeAliasPlaces(main_places),
          });

          costMobileOptinos.push({
            value: price,
            label: formatter(price),
          });
        }
      }
    }

    // Делаем уникальными и сортируем, если нужно
    spaciousnessMobileOptinos = uniqBy(spaciousnessMobileOptinos, 'value');
    categoryMobileOptinos = uniqBy(categoryMobileOptinos, 'value');
    deckMobileOptinos = uniqBy(deckMobileOptinos, 'value');
    costMobileOptinos = uniqBy(costMobileOptinos, 'value').sort(
      (a, b) => a.value - b.value,
    );

    return {
      spaciousnessMobileOptinos,
      costMobileOptinos,
      deckMobileOptinos,
      categoryMobileOptinos,
    };
  }

  // Фильтрация по частично занятыми каютам - 55
  get partlyOccupiedCabins() {
    return (
      this.freeCabins &&
      Object.values(this.filteredCabins)
        .filter(i => i.status === 55)
        .map(i => i.cabin_number)
    );
  }

  // Фильтрация по свободным каютам каютам
  get vacantCabins() {
    return Object.values(this.filteredCabins)
      .filter(i => i.status === 0)
      .map(i => i.cabin_number);
  }

  // Фильтрация по одноместным каютам в 2,3 и 4х местных каютах
  get vacantSingleCabins() {
    return Object.values(this.filteredCabins)
      .filter(i => i.status === 0 && this.filters?.spaciousness < i.main_places)
      .map(i => i.cabin_number);
  }

  // Подсветка кают
  highlightCabins() {
    if (isClient) {
      if (this.activeTab === 'scheme') {
        this.partlyOccupiedCabins.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemePartlyOccupiedCabins'),
        );
        this.vacantCabins.forEach(i =>
          document
            .getElementById(`k_${i}`)
            ?.setAttribute('class', 'schemeVacantCabins'),
        );

        if (this.filters?.spaciousness?.length !== 0) {
          this.vacantSingleCabins?.forEach(i =>
            document
              .getElementById(`k_${i}`)
              ?.setAttribute('class', 'schemeSingleCabinsColor'),
          );
        }
      }
    }
  }

  setFio(fio) {
    this.fio = fio;
  }

  setPhone(phone) {
    this.phone = phone;
  }

  setBitrixId(id) {
    this.bitrixId = id;
  }

  setShowTooltip(show) {
    this.isShowTooltip = show;
  }

  setDataTooltip(data) {
    this.dataTooltip = data;
  }

  isFunSun() {
    if (this.ownerId === 100025) {
      return true;
    }
    return false;
  }

  tooltipInfo() {
    if (isClient || this.activeTab === 'scheme') {
      const scheme = document.querySelector('.scheme');
      scheme?.addEventListener('mouseover', event => {
        const translitActiveCabin = translit(
          event.target.innerHTML,
        ).toLowerCase();

        const clickToCabin =
          event.target?.className?.animVal?.includes('CautaClick');
        // TODO: Копипаста
        const data =
          this.freeCabins &&
          Object.values(this.freeCabins).find(
            x => x.cabin_number === translitActiveCabin,
          );

        if (data && clickToCabin) {
          this.setShowTooltip(true);
          this.setDataTooltip({
            x: event.target.getBoundingClientRect().left - 58,
            y: event.target.getBoundingClientRect().top + 33,
            data: {
              cabinNumber: data?.cabin_number_orig ?? '',
              minPrice: data?.cabin_min_price ?? '',
              totalPlaces: data?.total_places ?? '',
              categoryCabin: data?.category ?? '',
              previewPhoto: data?.cabinPreview ?? '',
            },
          });
        }
      });

      scheme?.addEventListener('mouseout', () => {
        this.setShowTooltip(false);
      });
    }
  }

  setAgePassenger(agePassenger) {
    this.pickAge = agePassenger;
  }

  get extraPlaces() {
    return this.cabinData?.extra_places;
  }

  get cabinNumber() {
    return this.cabinData?.cabin_number_orig;
  }

  get mainPlaces() {
    return this.cabinData?.main_places;
  }

  get totalPlaces() {
    return this.cabinData?.total_places;
  }

  get categoryCabin() {
    return this.cabinData?.category;
  }

  get deckName() {
    return this.cabinData?.deckName ?? '';
  }

  get freePlaces() {
    return this.cabinData?.cabin_places;
  }

  get age() {
    this.chosenTariff();
    return this.freePlaces?.map(i => Object.keys(i?.place_tariffs?.tariffs));
  }

  get emptySurchargePrice() {
    return this?.cabinData?.empty_surcharge_price;
  }

  get food() {
    return this.freePlaces?.map(i => i?.place_tariffs?.tariffs);
  }

  get excursions() {
    return this.freePlaces?.map(i => i?.place_tariffs?.tariffs);
  }

  get discounts() {
    return this.discountsInfo;
  }

  get benefit() {
    const diffTotalPrice = this.unTotalPrice - this.totalPrice;
    return Math.ceil(
      diffTotalPrice === 0
        ? (this.unTotalPrice / 100) * this.percent
        : diffTotalPrice,
    );
  }

  removePassenger(index) {
    const REMOVED = 0;
    this.freePlaces[index].place_selected = REMOVED;
    this.lastPassenger =
      this.freePlaces.filter(place => place.place_selected === 1).length === 1;
    this.chosenTariff();
  }

  addPassenger() {
    const SELECTED = 1;
    const placesArray = Array.isArray(this.freePlaces)
      ? this.freePlaces
      : Object.values(this.freePlaces);

    const index = placesArray.findIndex(place => place.place_selected === 0);

    if (placesArray.length) {
      this.freePlaces[index].place_selected = SELECTED;
    }
    this.lastPassenger =
      this.freePlaces.filter(place => place.place_selected === 1).length === 1;
  }

  get hasChildOnMainPlaceCount() {
    return this.freePlaces
      ?.filter(place => place.type.code === 0)
      .filter(place => place.is_child === true).length;
  }

  get mainPlacesCount() {
    return toArray(this.freePlaces)?.filter(place => place.type.code === 0)
      .length;
  }

  get remainderMainPlaceCount() {
    return this.mainPlacesCount - this.hasChildOnMainPlaceCount;
  }

  get hideChildTarrif() {
    return this.remainderMainPlaceCount <= 1;
  }

  // Удаленные основные места, для плашки доплаты за выкуп основных мест
  get deletedMainPlaceCount() {
    return toArray(this.freePlaces)
      ?.filter(place => place.place_selected === 0)
      .filter(place => place.type.code === 0);
  }

  // Выбрано ли доп место, для скрытия кнопки.
  // Если выбрано доп место, нужно скрывать кнопку удалить у основных мест
  // type.code 1 - доп место
  // type.code 0 - основное место
  get hasSelectedExtraPlaces() {
    this.chosenTariff();
    return toArray(this.freePlaces)?.some(
      place => place.type.code === 1 && place.place_selected === 1,
    );
  }

  // определяем какой тариф выбран
  chosenTariff() {
    this.selectedTariff = this.freePlaces
      ?.filter(place => place.is_free === 1)
      .filter(place => place.place_selected === 1)
      .map(i => {
        if (i?.tariff_id === undefined) {
          return i?.selected_tariff_id;
        }
        return i?.tariff_id;
      });
  }

  // считаем сумму доплаты
  seatCost(tariff, data) {
    const keys = Object.keys(data ?? []);
    if (this.ownerId === 1006 || this.ownerId === 2) {
      return keys.map(key => {
        if (key === tariff) {
          return (
            data[key][this.deletedMainPlaceCount?.length] /
            this.selectedTariff.length
          );
        }
        return 0;
      });
    }

    return keys.map(key => {
      if (Number(key) === Number(tariff)) {
        return (
          data[key][this.deletedMainPlaceCount?.length] /
          this.selectedTariff.length
        );
      }
      return 0;
    });
  }

  get discountPercent() {
    return this.freePlaces
      ?.filter(place => place.is_free === 1)
      .filter(place => place.place_selected === 1)
      .map(place =>
        place?.place_tariffs?.place_discounts
          ? place?.place_tariffs?.place_discounts[0]?.value
          : 0,
      )[0];
  }

  seatSupplement() {
    const seatCost = this.selectedTariff?.map(tariff =>
      this.seatCost(tariff, this?.cabinData?.new_empty_surcharge_price).filter(
        i => i !== 0,
      ),
    );
    return Math.round(
      seatCost?.reduce((acc, curr) => Number(acc) + Number(curr), 0),
    );
  }

  get surchargePrice() {
    const totalPlaces = this.cabinData?.total_places;
    const selectedPlaces = this.selectedTariff?.length;
    const extraSurcharge = totalPlaces - selectedPlaces;

    if (
      this.ownerId === 4 &&
      this.deletedMainPlaceCount?.length === 0 &&
      totalPlaces - selectedPlaces !== 0
    ) {
      return (
        this.cabinData?.empty_surcharge_price &&
        this.cabinData?.empty_surcharge_price[0] * extraSurcharge
      );
    }
    if (this.deletedMainPlaceCount?.length !== 0) {
      if (this.ownerId === 3 || this.ownerId === 1006 || this.ownerId === 2) {
        return this.seatSupplement();
      }
      if (this.ownerId === 4) {
        return (
          this.emptySurchargePrice && this.emptySurchargePrice[extraSurcharge]
        );
      }
      return (
        this.emptySurchargePrice &&
        this.emptySurchargePrice[this.deletedMainPlaceCount?.length]
      );
    }
    return 0;
  }

  get countPassengers() {
    return this.selectedTariff?.length;
  }

  // Очищаем выбранный тариф, перед выбором другого тарифа
  clearTariff(index) {
    const tariffs = this.freePlaces[index]?.place_tariffs?.tariffs;
    for (const key in tariffs) {
      if (Object.prototype.hasOwnProperty.call(tariffs, key)) {
        if (tariffs[key]?.find(i => i?.selected !== 0)) {
          tariffs[key].find(i => i?.selected === 1).selected = 0;
        }
      }
    }
  }

  setMealSelected(index) {
    const { tariffs, place_discounts } = this.freePlaces[index].place_tariffs;
    const places = this.freePlaces[index];

    const currentMealoptions = tariffs[places?.passenger_type_selected].find(
      i => i.selected === 1,
    );
    places.notSale = currentMealoptions?.not_sale;
    places.count_tariff = currentMealoptions.count_tariff ?? null;
    places.price = parseInt(
      places.notSale
        ? currentMealoptions.undiscounted_price
        : currentMealoptions.option_price,
      10,
    );
    places.passenger_type_selected_price = parseInt(
      places.notSale
        ? currentMealoptions.undiscounted_price
        : currentMealoptions.option_price,
      10,
    );
    places.passenger_type_selected_price += places.excursions_price;
    places.price += places.excursions_price;
    places.selected_meal_type = currentMealoptions.option_name;
    places.unPrice = currentMealoptions.undiscounted_price;
    places.tariff_id = currentMealoptions.tariff_id;
    places.total_price = parseInt(this.totalPrice, 10);
    if (places.passenger_type_selected.toLowerCase().includes('дет')) {
      places.is_child = true;
    } else {
      places.is_child = false;
    }

    // передаю на бэк инфу о % и сумме скидки
    if (place_discounts && place_discounts.length > 0) {
      this.cabinData.discounted_value = place_discounts[0].value;
      this.cabinData.discounted_price = this.unTotalPrice - this.totalPrice;
    }
  }

  setExcursionSelected(price, index) {
    const { tariffs, place_discounts } = this.freePlaces[index].place_tariffs;
    const place = this.freePlaces[index];

    const currentMealoptions = tariffs[place?.passenger_type_selected].find(
      i => i.selected === 1,
    );

    const selectedExcursion = place.excursion.find(
      excur => Object.values(excur)[0] === price,
    );

    place.excursions_price = price;
    place._price = parseInt(currentMealoptions.option_price, 10);

    place.passenger_type_selected_price = place._price;
    place.passenger_type_selected_price += place.excursions_price;

    place.price = place._price;
    place.price += place.excursions_price;
    place.excursions_selected = selectedExcursion;
    if (place.excursions_price === 0) {
      place.unPrice = place.price;
    }
    if (place.excursions_price !== 0) {
      place.unPrice += place.excursions_price;
    }
    place.total_price = this.totalPrice;
    // передаю на бэк инфу о % и сумме скидки
    if (place_discounts && place_discounts.length > 0) {
      this.cabinData.discounted_value = place_discounts[0].value;
      this.cabinData.discounted_price = this.unTotalPrice - this.totalPrice;
    }
  }

  setPassengerTypeSelected(age, index) {
    const { tariffs } = this.freePlaces[index].place_tariffs;
    this.freePlaces[index].passenger_type_selected = age;
    this.setCurrentAge(age);

    const initialFoodTariff =
      this.currentFood || this.freePlaces[index].selected_meal_type;

    if (tariffs[age].every(i => i.option_name !== initialFoodTariff)) {
      this.clearTariff(index);
      tariffs[age][0].selected = 1;
      this.setMealSelected(index);
    } else {
      this.clearTariff(index);
      tariffs[age].find(i => i.option_name === initialFoodTariff).selected = 1;
      this.setMealSelected(index);
    }
  }

  setCurrentFood(food) {
    this.currentFood = food;
  }

  setCurrentAge(age) {
    this.currentAge = age;
  }

  setTariff(food, index) {
    const { tariffs } = this.freePlaces[index].place_tariffs;
    this.clearTariff(index);
    this.setCurrentFood(food);
    const age = this.freePlaces[index]?.passenger_type_selected;
    tariffs[age].find(i => i?.option_name === food).selected = 1;
    this.setMealSelected(index);
  }

  defaultLastPassenger() {
    this.freePlaces
      .filter(i => i.place_selected === 1)
      .forEach((place, key) => {
        const tariffAge = this.age[key].map(tariff => tariff);
        const [firstTariff] = tariffAge;

        const { place_discounts } = place.place_tariffs;

        place.passenger_type_selected = firstTariff;

        this.setPassengerTypeSelected(firstTariff, key);

        const initialTariff = this.food[key][place.passenger_type_selected][0];
        const initialExcursionsPrice =
          initialTariff?.excursion && initialTariff?.excursion[0];
        const excursionsPrice =
          initialExcursionsPrice && Object.values(initialExcursionsPrice)[0];

        place.passenger_type_selected = firstTariff;
        place.passenger_type_selected_price = parseInt(
          initialTariff.option_price,
          10,
        );
        place.passenger_type_selected_price = parseInt(
          initialTariff.option_price,
          10,
        );
        place.selected_meal_type = initialTariff.option_name;
        place.unPrice = initialTariff.undiscounted_price;
        place.selected_tariff_id = initialTariff.tariff_id;
        place.total_price = this.totalPrice;
        place.notSale = initialTariff?.not_sale;
        place.excursions_price = excursionsPrice ?? 0;
        place.excursions_selected = initialExcursionsPrice;
        place.passenger_type_selected_price += place.excursions_price;
        place.price = place.passenger_type_selected_price;
        place._price = initialTariff.passenger_type_selected_price;
        place.total_price = this.totalPrice;
        // передаю на бэк инфу о % и сумме скидки
        if (place_discounts && place_discounts.length > 0) {
          this.cabinData.discounted_value = place_discounts[0]?.value;
          this.cabinData.discounted_price = this.unTotalPrice - this.totalPrice;
        }
      });
  }

  countPassengerFunSun() {
    const countPassenger = this.freePlaces[0].count_tariff;

    this.freePlaces?.forEach((place, key) => {
      const count = key + 1;
      if (count <= countPassenger) {
        place.place_selected = 1;
      } else {
        place.place_selected = 0;
      }
    });
  }

  // Ставлю дефолтные значения для тарифа
  // Пока не знаю для чего какие свойства, делаю как на старом сайте 😅
  defaultPassengerTypeSelected() {
    const SELECTED = 1;
    this.freePlaces?.forEach((place, key) => {
      const tariffAge = this.age[key].map(tariff => tariff);
      const [firstTariff] = tariffAge;

      const { place_discounts } = place.place_tariffs;

      place.passenger_type_selected = firstTariff;

      const initialTariff = this.food[key][place.passenger_type_selected][0];
      const initialExcursions = this.food[key][place.passenger_type_selected][0]
        .excursion ?? [{}];
      const excursionsPrice = Object.values(initialExcursions[0])[0] ?? 0;
      const specialOffer = initialTariff?.special_offer === '1' ?? false;

      this.specialOffer = specialOffer;

      initialTariff.selected = SELECTED;

      place.passenger_type_selected = firstTariff;
      place.passenger_type_selected_price = parseInt(
        initialTariff.option_price,
        10,
      );
      place.passenger_type_selected_price = parseInt(
        initialTariff.option_price,
        10,
      );
      place.is_child = initialTariff.is_child;
      place.selected_meal_type = initialTariff.option_name;
      place.unPrice = initialTariff.undiscounted_price;
      place.selected_tariff_id = initialTariff.tariff_id;
      place.notSale = initialTariff?.not_sale;
      place.excursion = initialExcursions;
      place.excursions_price = excursionsPrice;
      place.passenger_type_selected_price += place.excursions_price;
      place._price = initialTariff.passenger_type_selected_price;
      place.price = initialTariff.option_price + place.excursions_price;
      const selectedExcursion = initialExcursions.find(
        excur => Object.values(excur)[0] === place.excursions_price,
      );
      place.excursions_selected = selectedExcursion;
      place.total_price = this.totalPrice;
      place.unPrice += place.excursions_price;
      // передаю на бэк инфу о % и сумме скидки
      if (place_discounts && place_discounts.length > 0) {
        this.cabinData.discounted_value = place_discounts[0].value;
        this.cabinData.discounted_price = this.unTotalPrice - this.totalPrice;
      }

      if (place.type.code !== 1) {
        place.place_selected = SELECTED;
      }
    });
    this.setSpecialOffer();
  }

  // get totalPrice() {
  //   return (
  //     this.surchargePrice +
  //     this.freePlaces
  //       ?.filter(place => place.is_free === 1)
  //       .filter(place => place.place_selected === 1)
  //       .reduce((acc, curr) => acc + curr.passenger_type_selected_price, 0)
  //   );
  // }

  // РБ не считается по флагу notSale
  get totalPrice() {
    // Расчет скидки у Водохода (для корректной итоговой цены)
    const surge =
      this.ownerId === 3
        ? Math.round(this.surchargePrice * (this.discountPercent / 100))
        : 0;

    return (
      this.surchargePrice +
      this.freePlaces
        ?.filter(place => place.is_free === 1)
        .filter(place => place.place_selected === 1)
        .reduce((acc, curr) => {
          if (curr.notSale === true) {
            return acc + curr.unPrice;
          }
          return acc + curr.passenger_type_selected_price;
        }, 0) -
      surge
    );
  }

  get unTotalPrice() {
    return (
      this.surchargePrice +
      this.freePlaces
        ?.filter(place => place.is_free === 1)
        .filter(place => place.place_selected === 1)
        .reduce((acc, curr) => acc + curr.unPrice, 0)
    );
  }

  setSelectedCabins() {
    this.selectedCabins = this.pickedCabin.reduce(
      (a, v) => ({ ...a, [v.cabin_number]: v }),
      [],
    );
  }

  setEmptySurchargeCabin() {
    this.cabinData.empty_surcharge = this.surchargePrice;
  }

  defaultPlaceSetting() {
    this.cabinData.total_price = this.totalPrice;
  }

  setPromocodeDiscount() {
    if (this.percent) {
      this.cabinData.total_price = Math.floor(
        this.sumDiscount(this.totalPrice, this.percent),
      );
    } else {
      this.cabinData.total_price = this.totalPrice;
    }
  }
  // пороверяем подходит ли круиз под рассрочку + смотрим, чтобы итоговая стоимость каюты была больше 100кyarn
  setInstallmentPlan() {
    // откатил рассрочку
    this.installmentPlan = false;
    // откатил рассрочку
    // if (this.installment && this.allTotalPrice >= 100000) {
    //   this.installmentPlan = true;
    // } else {
    //   this.installmentPlan = false;
    // }
  }

  async makeBooking() {
    if (isClient) {
      const { api } = this;

      this.root.bookingStore.clearOrderId();

      const { isLoggedIn } = this.root.authStore;

      this.addCaute();
      this.updateValues();
      const serializeData = JSON.stringify(this.selectedCabins);
      this.setInstallmentPlan();

      await api
        .sendCabInfo({
          cabContent: serializeData,
          fio: this.fio,
          phone: this.phone,
          bitrixId: this.bitrixId,
          user_parent_id: this.root.authStore.id || null,
        })
        .then(res => {
          if (res.status === 200) {
            this.selectedCabinsStorage = res.data.cab_content;
            this.cruiseStorage = res.data.cruise_data;
            this.amountDiscount = res.data.discounted_price;
            this.percentDiscount = res.data.discounted_value;

            if (!isLoggedIn) {
              Router.push(
                {
                  pathname: `/step_1`,
                },
                undefined,
                { scroll: true },
              );
            } else {
              Router.push(
                {
                  pathname: `/step_2`,
                },
                undefined,
                { scroll: true },
              );
            }
          }
        })
        .catch(e => console.log('ERRORS', e));
    }
  }

  async initialBooking() {
    this.addCaute();
    this.updateValues();
    const serializeData = JSON.stringify(this.selectedCabins);
    // start()
    await this.root.booking.startBooking(serializeData);
  }

  sendCabinFacilities = params => this.api.sendCabinFacilities(params);

  test() {
    if (isClient) {
      this.updateValues();
      const input = document.getElementById('cab_content');
      const phone = document.getElementById('cl_phone');
      const fio = document.getElementById('cl_fio');
      phone.value = this.phone;
      fio.value = this.fio;
      const serializeData = JSON.stringify(this.selectedCabins);
      input.value = serializeData;
      this.addCaute();
    }
  }

  addCaute() {
    if (this.cabinData) {
      this.cabinData.isSelected = true;
    }
  }

  removeCaute(id) {
    if (this.freeCabins) {
      this.freeCabins[id].isSelected = false;
    }
  }

  get hasPickedCabin() {
    return this.cabinData?.isSelected;
  }

  get pickedCabin() {
    return Object.values(this.freeCabins).filter(cabin => cabin?.isSelected);
  }

  get allTotalPrice() {
    return this.pickedCabin.reduce((acc, curr) => acc + curr.total_price, 0);
  }

  get сalculatedTotalPrice() {
    if (this.hasPickedCabin) {
      return this.allTotalPrice;
    }
    return this.totalPrice + this.allTotalPrice;
  }

  get isLastCabin() {
    return Object.values(this.freeCabins).length > 1;
  }

  setDefaultTotalPrice() {
    this.freePlaces.forEach(place => ({
      ...place,
      total_price: this.totalPrice,
    }));
  }

  updateValues() {
    if (this.toggledModal) {
      this.defaultPlaceSetting();
      this.setDefaultTotalPrice();
      this.setEmptySurchargeCabin();
      this.setPromocodeDiscount();
      this.setSelectedCabins();
    }
  }

  async addToSelection() {
    const { api } = this;
    this.updateValues();
    const lel = [this.cabinData].reduce(
      (a, v) => ({ ...a, [v.cabin_number]: v }),
      [],
    );
    for (const prop in lel) {
      if (Object.hasOwnProperty.call(lel, prop)) {
        const cabin = lel[prop];
        for (const kek of cabin.cabin_places) {
          kek.total_price = cabin.total_price;
        }
      }
    }
    this.updateValues();
    const desc = `${this.commonData.description}. ${this.commonData.features}`;

    await api
      .addToSelection(this.id, desc, JSON.stringify(lel))
      .then(() => alert('Добавлено в подбор!'));
  }

  get hasDiscounts() {
    return this.freePlaces && this.freePlaces.some(place => place.unPrice > 0);
  }

  get countFreePlaces() {
    return this.freePlaces.filter(place => place.is_free).length;
  }

  get countOccupiedPlaces() {
    return this.freePlaces.filter(place => !place.is_free).length;
  }

  get hasSharingCautes() {
    return !!this.countOccupiedPlaces;
  }

  setSpecialOffer() {
    this.specialOffer = this.cruiz?.cruise?.special_offer;
    this.specialOfferValue = this.cruiz?.cruise?.special_offer_value;
  }

  async activatePromoCode(promocode) {
    const { api } = this;
    await api
      .activatePromoCode(promocode)
      .then(res => {
        const { code, percent, status } = res.data;
        if (status === 1) {
          runInAction(() => {
            this.isActivated = true;
            this.promocode = code;
            this.percent = percent;
          });
        } else {
          throw new Error('error code');
        }
      })
      .then(() => {
        Router.reload(window.location.pathname);
      });
  }

  // promocode;
  // promoStatus
  hydrate(data = {}) {
    this.agencyRate = data?.cruiz?.cruise?.agency_rate;
    this.rawFreeCabins = data?.places?.free_cabins ?? [];
    this.cauteInfo = data?.places?.cat_info;
    this.mobileCabins = data?.places?.mobile_cabin_list;
    this.cabin2cat = data?.places?.cabin2cat;
    this.cruiz = data?.cruiz;
    this.installment = data?.cruiz?.ship_info.is_installment_allowed;
    this.discountsInfo = data?.places?.cabin_global_discounts ?? [];
    this.id = data?.idCruiz;
    this.ownerId = data?.cruiz?.ship_info?.owner_id;
    this.error = data?.places?.error ?? null;
  }
}
