import nookies from 'nookies';
import { oldApi, apiBff } from '../lib/api';

export class AuthApi {
  async getSms(phone, token, isNewStep) {
    return await oldApi.post(`/ApiUsers/get_auth_smscode`, {
      login: phone,
      'captcha-response': token,
      is_new_step: isNewStep,
    });
  }

  async getPhonePolling(phone) {
    return await oldApi.post(`/ApiUsers/check_auth_sms_status`, {
      login: phone,
    });
  }

  async getTokenBySms(code, phone) {
    return await oldApi.post(`/ApiUsers/auth_by_smscode`, {
      login: phone,
      sms_code: code,
      ym_cid: nookies.get(null)._ym_uid || null,
    });
  }

  async me(token) {
    return await oldApi.post(`/ApiUsers/check_token/${token}`, { token });
  }

  async setCookieToServer(cookieName, cookieValue) {
    return await apiBff.post(`/api/set-cookie`, { name: cookieName, value: cookieValue });
  }
  

  async logOut() {
    return await apiBff.post(`/api/logout`, {});
  }
}
