// import v8 from 'v8';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import d from 'dayjs';
import { polyfill } from 'interweave-ssr';
import { injectStyle } from 'react-toastify/dist/inject-style';
import '../styles/global.sass';
import '../styles/datepicker.sass';
import '../styles/nprogress.sass';
import 'react-toastify/dist/ReactToastify.css';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { prepareClientPortals } from '@jesstelford/react-portal-universal';
import { useUserAgent } from 'next-useragent';
import { isClient } from '../utils/isClient';
import { ErrorBoundary } from '../components/ErrorBoundary';
// import { getSnapshot } from '../lib/profiling/getShapshot'
// import { createSnapshot } from '../lib/profiling/createSnapshot';
// import {cron} from '../utils/cron';

// const cron = require("node-cron");

// export function reportWebVitals(metric) {
//   const body = JSON.stringify(metric)
//   const url = 'http://{ADRESS_TO_SERVER}:2113/vitals'

//   // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
//   if (navigator.sendBeacon) {
//       navigator.sendBeacon(url, body)
//   } else {
//       fetch(url, { body, method: 'POST', keepalive: true })
//   }
// }

require('dayjs/locale/ru');

d.locale('ru');

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

NProgress.configure({ showSpinner: false, parent: '#np' });

if (typeof window !== 'undefined') {
  // On the client, we have to run this once before React attempts a render.
  // Here in _app is a great place to do it as this file is only required once,
  // and right now (outside the constructor) is before React is invoked.
  prepareClientPortals();
}

const CruiseApp = ({ Component, pageProps }) => {
  polyfill();

  const ua = useUserAgent(isClient && window?.navigator.userAgent);
  const { isMobile, isTablet } = ua;
  const router = useRouter();

  useEffect(() => {
    toast();
    injectStyle();
  }, []);

  useEffect(() => {
    if (isClient && router.pathname === '/black_friday') {
      document.body.style.backgroundColor = '#000';
    } else {
      document.body.style.backgroundColor = '#ffffff';
    }
  }, [router.pathname]);

  // function countdown(n) {
  //   if (n <= 0) {
  //     console.log(123);
  //   } else {
  //     // Запускаем таймер на следующий шаг
  //     setTimeout(countdown, 1000, n - 1);
  //   }
  // }

  // countdown(1000); // Запускаем таймер со значением 10

  // timeout()

  return (
    <ErrorBoundary isMobile={isMobile} isTablet={isTablet}>
      <Component {...pageProps} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ErrorBoundary>
  );
};

export default CruiseApp;
