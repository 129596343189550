/* eslint-disable no-await-in-loop */
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Router from 'next/router';
import { toast } from 'react-toastify';
import nookies from 'nookies';
import dayjs from 'dayjs';
import { StorageManager } from '../lib/StorageManager';

const isBrowser = typeof window !== 'undefined';

export class BookingStore {
  api;
  root;
  places;
  data;
  order;
  cabinsInfo;
  isSubscribed;
  fio;
  phone;
  bitrixId;
  orderId;
  cabinsData;
  passengerData;
  paymentOptions;
  paymentMethods;
  orderFullPrice;
  orderHalfPrice;
  prepayDays;
  cruiseStart;
  cardOnlineConfig;
  sbpData;
  isSea;
  sbpToggledModal;
  sbpStatus;
  paymentCheckTimer;
  isMobile;
  promoPercent;
  promoName;
  promoPrice;
  promoCode;
  totalPrice;
  isLoading;
  // benefitPromo;
  isExisted;
  idTinkoff;
  isDisabledOnlinePay;
  amountDiscount;
  percentDiscount;
  installmentPlan;
  installmentLoading;
  ownerInfo;
  orderMetricData = {};
  paymentMetricData = {};
  activeToken;

  static createOrderData(data) {
    return {
      ship: data?.t_title,
      route: data?.route_full,
      dateStart: data?.kr_date_start,
      dateEnd: data?.kr_date_end,
      dateDays: data?.kr_days,
      dateNights: data?.kr_nights,
      year: Number(dayjs(data?.kr_date_start).format('YYYY')),
    };
  }

  constructor(root, api) {
    const userId = StorageManager.get('orderId');
    this.root = root;
    this.api = api;
    this.places = {};
    this.data = {};
    this.orderId = userId ?? '';
    this.cabinsData = [];
    this.orderFullPrice = '';
    this.orderHalfPrice = '';
    this.sbpToggledModal = false;
    this.paymentCheckTimer = null;
    this.isLoading = true;
    this.installmentLoading = false;
    this.paymentOptions = '100';
    this.user = {
      surname: '',
      name: '',
      patronymic: '',
      phone: '',
      clientId: '',
    };
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'BookingStore',
      storage: isBrowser ? window.localStorage : undefined,
      properties: ['isSubscribed', 'orderMetricData', 'paymentMetricData'],
    });
  }

  async getPersistedData() {
    const cruiseData = await StorageManager.get('CruizStore');
    const seaCruiseData = await StorageManager.get('SeaCruizStore');

    const data = cruiseData?.selectedCabinsStorage ? cruiseData : seaCruiseData;

    this.cauteInfo = data?.selectedCabinsStorage ?? {};
    this.cruiz = data?.cruiseStorage;
    this.fio = data?.fio ?? null;
    this.phone = data?.phone ?? null;
    this.bitrixId = data?.bitrixId ?? null;
    this.isDisabledOnlinePay = this.cruiz?.is_disabled_online_pay ?? false;
    this.installmentPlan = data?.installmentPlan;

    this.amountDiscount = data?.amountDiscount;
    this.percentDiscount = data?.percentDiscount;
    this.prepayDays = this.cruiz?.prepay_days;
    this.cruiseStart = this.cruiz?.kr_date_start;
    this.isSea = Object.entries(this.cauteInfo)
      .map(([cab, info]) => info.isSeaCabin)
      .at(0);
  }

  getOrderInfo() {
    this.order = BookingStore.createOrderData(this.cruiz);
    this.cabinsInfo = Object.entries(this.cauteInfo).map(([cab, inf]) => ({
      cabins: cab,
      info: Object.values(inf.cabin_places).filter(i => i.place_selected === 1),
      emptySurcharge: inf.empty_surcharge,
    }));
    const rawTotalPrice = Object.entries(this.cauteInfo).map(([cab, inf]) => ({
      totalPrice: inf.total_price,
    }));
    this.totalPrice = rawTotalPrice
      .reduce((acc, curr) => acc + curr.totalPrice, 0)
      .toString();
  }

  // Action для записи данных
  setOrderAndPaymentMetricData(orderMetricData, paymentMetricData) {
    this.orderMetricData = orderMetricData;
    this.paymentMetricData = paymentMetricData;
  }

  async sendSingUpForm(values) {
    const { api } = this;
    const { api: authApi } = this.root.authStore;
    const { surname, name, patronymic, phone, isSubscribed } = values;

    await api
      .sendFirstStepForm({
        phone,
        isSubscribed,
        isSea: this.isSea,
      })
      .then(({ data }) => {
        const isNewStep = true;

        runInAction(() => {
          this.token = data.token;
          this.isSubscribed = isSubscribed;

          this.isExisted = data.is_existed;

          this.user.surname = surname;
          this.user.name = name;
          this.user.patronymic = patronymic;
          this.user.phone = phone;
          this.user.clientId = data.client_id;

          this.root.authStore.setPhone(phone);
          this.root.authStore.phone = phone;
        });

        if (this.isExisted) {
          this.root.authStore.setToggledModal(true, 'authSteps');
          authApi.getSms(phone, '', isNewStep).then(res => {
            const { status } = res.data;
            if (status === 1) {
              this.root.authStore.setStep('codeInput');
            } else if (status === 2) {
              this.root.authStore.setStep('loginAttempts');
            } else {
              throw new Error('error phone');
            }
          });
        } else {
          this.authUser();
        }
      });
  }

  prepDate(date) {
    if (date === '0000-00-00') {
      return null;
    }
    if (date === '1899-11-30') {
      return null;
    }
    return dayjs(date).format('DD.MM.YYYY');
  }

  prepareOwnerInfo(data) {
    return {
      ...data,
      prepBirthday: this.prepDate(data.birthday),
      passDate: this.prepDate(data.pasport_date),
      passNumber: data.pasport_n,
      passOrgan: data.pasport_organ,
      passp_type: data.pasport_type,
      gender: data.sex,
    };
  }

  async authUser() {
    const { api: authApi } = this.root.authStore;
    await authApi.setCookieToServer('token', this.token).then(() => {
      runInAction(() => {
        this.root.authStore.name = this.user.name;
        this.root.authStore.surname = this.user.surname;
        this.root.authStore.patronymic = this.user.patronymic;
        this.root.authStore.phone = this.user.phone;
        this.root.authStore.id = this.user.clientId;
      });
    });
    await Router.push(
      {
        pathname: `/step_2`,
      },
      undefined,
      { scroll: true },
    );
  }

  async callBackPayment(paymentOption, paymentMethod) {
    const { api } = this;
    if (paymentOption === '100' && paymentMethod === '2') {
      await api.callBackPayment(this.cardOnlineConfig?.tinkoff?.fullpay?.link);
    } else if (paymentOption === '50' && paymentMethod === '2') {
      await api.callBackPayment(this.cardOnlineConfig?.tinkoff?.prepay?.link);
    } else if (paymentOption === '100' && paymentMethod === '3') {
      await api.callBackPayment(this.cardOnlineConfig?.sber_pay?.fullpay?.link);
    } else if (paymentOption === '50' && paymentMethod === '3') {
      await api.callBackPayment(this.cardOnlineConfig?.sber_pay?.prepay?.link);
    } else {
      await api.callBackPayment(null);
    }
  }

  async getOwnerParamsByToken() {
    const { api } = this;
    try {
      await api.getOwnerParamsByToken(this.token).then(res => {
        runInAction(() => {
          this.ownerInfo = this.prepareOwnerInfo(res?.data);
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  async creatOrder() {
    const { api } = this;

    await this.getPersistedData();
    const cabContent = JSON.stringify(this.cauteInfo);
    const isSubscribed = JSON.stringify(this.isSubscribed);

    await api
      .createOrder({
        cabContent,
        isSubscribed,
        token: this.token || this.root.authStore.token,
        isSea: this.isSea,
        fio: this.fio,
        phone: this.phone,
        bitrixId: this.bitrixId,
        orderId: this.orderId,
        user: this.root.authStore.id || null,
        ym_cid: !this.root.authStore.isManager
          ? nookies.get(null)._ym_uid || null
          : null,
      })
      .then(res => {
        if (res.status === 200) {
          this.orderId = res.data.order_id;
          this.cauteInfo = res.data.cab_content;
          StorageManager.set('orderId', this.orderId);
          this.notifyError = res.data.cab_content.error;

          if (this.root.authStore.group === '5') {
            if (this.notifyError) {
              toast.error(this.notifyError);
            }
          }
        }
      });
  }

  async operatorReserve() {
    const { api } = this;

    const cabContent = JSON.stringify(this.cauteInfo);

    if (this.orderId && !this.isSea) {
      await api.operatorReserve(cabContent);
    }
  }

  async getStep2() {
    const { api } = this;
    if (this.isSea) {
      await api.getStep2Sea({ orderId: this.orderId }).then(({ data }) => {
        runInAction(() => {
          this.cabinsData = data;
          this.isLoading = false;
          this.activeToken = data[0].token ?? '';
        });
      });
    } else {
      this.isLoading = true;
      await api
        .getStep2({ orderId: this.orderId, isSea: this.isSea })
        .then(({ data }) => {
          runInAction(() => {
            this.cabinsData = data;
            this.isLoading = false;
            this.activeToken = data[0].token ?? '';
          });
        });
    }
  }

  async sendPassengers() {
    const { api } = this;

    const serializeData = JSON.stringify(this.orderData);

    await api.sendPassengersData(serializeData, this.isSea).then(res => {
      if (res.status === 200 && this.isDisabledOnlinePay) {
        Router.push(
          {
            pathname: `/thanksStep`,
            query: {
              type: 'isDisabledOnlinePay',
              // sbp: 1,
              order: this.orderId,
            },
          },
          undefined,
          { scroll: true },
        );
      }
      if (res.status === 200 && !this.isDisabledOnlinePay) {
        Router.push(
          {
            pathname: `/step_3`,
          },
          undefined,
          { scroll: true },
        );
      }
    });
  }

  async getStep3() {
    const { api } = this;
    await this.getPersistedData();
    const orderId = await StorageManager.get('orderId');
    await api.getStep3({ orderId, isSea: this.isSea }).then(({ data }) => {
      this.orderFullPrice = data.summa;
      this.orderHalfPrice = data.order_price_50;
      this.orderFullPrice = data.summa;

      this.root.promoCode.setPromoPrice(data.promo_sum);
      this.root.promoCode.setPromoName(data.promo_name);

      this.orderHalfPrice = data.order_price_50;
      // this.benefitPromo = data.defaultSumma - data.summa;
    });
  }

  async sendInstallmentPlan() {
    const { api } = this;

    this.installmentLoading = true;

    api.sendInstallment(this.orderId).then(res => {
      runInAction(() => {
        if (res.status === 200) {
          Router.push(
            {
              pathname: `/thanksStep`,
              query: {
                type: 'installmentPlan',
                order: this.orderId,
              },
            },
            undefined,
            { scroll: true },
          );
          this.installmentLoading = false;
        }
      });
    });
  }

  async getPay(value) {
    const { api } = this;

    // this.paymentMethods = value;
    // TODO: ПОФИКСИТЬ ЭТУ ДИЧЬ
    if (value !== '1' && this.orderFullPrice) {
      await api.paymentQR(this.paymentQR, this.isSea).then(({ data }) => {
        runInAction(() => {
          this.sbpData = {
            qrCode: data.qr_code,
          };
          this.idTinkoff = data.id_tinkoff;
        });
      });
    } else {
      await api
        .paymentCard(this.paymentCardOnline, this.isSea)
        .then(({ data }) => {
          runInAction(() => {
            this.cardOnlineConfig = data;
          });
        });
    }
  }

  async checkStatusSBP() {
    const { api } = this;
    this.paymentCheckTimer = setInterval(() => {
      api
        .sbpChecked(this.idTinkoff)
        .then(response => {
          const { status } = response.data;
          if (status === 'CONFIRMED') {
            this.SetSbpToggledModal(false);
            this.stopPaymentCheck();
            this.setSbpStatus(status);
            // Внутри функции handlePaymentClick
            localStorage.setItem(
              'orderMetricData',
              JSON.stringify(this.orderMetricData),
            );
            localStorage.setItem(
              'paymentMetricData',
              JSON.stringify(this.paymentMetricData),
            );

            Router.push(
              {
                pathname: `/thanksStep`,
                query: {
                  type: this.paymentOptions,
                  sbp: 1,
                  order: this.orderId,
                },
              },
              undefined,
              { scroll: true },
            );
          }
        })
        .catch(error => {
          console.error(error);
        });
    }, 2000);
  }

  stopPaymentCheck() {
    if (this.paymentCheckTimer) {
      clearInterval(this.paymentCheckTimer);
      this.paymentCheckTimer = null;
    }
  }

  setSbpStatus(status) {
    this.sbpStatus = status;
  }

  setPaymentOptions(values) {
    this.paymentOptions = values;
  }

  setPaymentMethods(values) {
    this.paymentMethods = values;
  }

  setPassengerData(data) {
    this.passengerData = data;
  }

  SetSbpToggledModal(toggled) {
    this.sbpToggledModal = toggled;
  }

  get isInstallment() {
    return this.paymentOptions === 'installmentPlan';
  }

  get getSberPayLink() {
    return this.paymentOptions === '100'
      ? this.cardOnlineConfig?.sber_pay?.fullpay?.link
      : this.cardOnlineConfig?.sber_pay?.prepay?.link;
  }

  async applyPromoCode({ promo }) {
    const { api } = this;

    const { api: apiPromo } = this.root.promoCode;

    await apiPromo
      .submitPromoCode(this.orderId, promo)
      .then(data => {
        runInAction(() => {
          this.root.promoCode.setPromoPercent(data?.data?.percent);
          this.root.promoCode.setPromoName(data?.data?.feedback);
          this.root.promoCode.setPromoPrice(data?.data?.promo_sum);
          this.root.promoCode.setPromoCode(data.data);
        });
      })
      .then(() => {
        api
          .getStep3({ orderId: this.orderId, isSea: this.isSea })
          .then(({ data }) => {
            runInAction(() => {
              this.orderFullPrice = data.summa;

              this.root.promoCode.setPromoPrice(data.promo_sum);
              this.root.promoCode.setPromoName(data.promo_name);

              this.orderHalfPrice = data.order_price_50;
            });
          });
      });
  }

  clearDataBooking() {
    StorageManager.delete('CruizStore');
    StorageManager.delete('SeaCruizStore');
    StorageManager.delete('orderId');
    StorageManager.delete('BookingStore');

    this.root.authStore.removeBookingSteps();
  }

  clearOrderId() {
    this.orderId = '';
  }

  // TODO: добавить поле data_type если комп - IMAGE, если мобила - PAYLOAD
  get paymentQR() {
    return {
      order_id: Number(this.orderId),
      amount:
        this.paymentOptions === '100'
          ? Number(this.orderFullPrice) * 100
          : (Number(this.orderFullPrice) / 2) * 100,
      pay_percent: Number(this.paymentOptions),
      data_type: this.deviceTypeSbp,
    };
  }

  get paymentCardOnline() {
    const orderId = StorageManager.get('orderId');
    return {
      order_id: Number(this.orderId) || orderId,
      cruis_start: this.cruiseStart,
      pay_percent: Number(this.paymentOptions),
      ship_prepay_days: Number(this.prepayDays),
    };
  }

  get orderData() {
    return {
      order_id: this.orderId,
      passengers: this.passengerData,
    };
  }

  get qrCode() {
    return {
      [this.deviceTypeSbp === 'IMAGE' ? 'image' : 'link']: this.sbpData?.qrCode,
    };
  }

  get cardOnlineLink() {
    return this.paymentOptions === '100'
      ? this.cardOnlineConfig?.tinkoff?.fullpay?.link
      : this.cardOnlineConfig?.tinkoff?.prepay?.link;
  }

  get deviceTypeSbp() {
    return this.isMobile ? 'PAYLOAD' : 'IMAGE';
  }

  get choicePayment() {
    const fullPay = {
      value: '100',
      label: 'Полная оплата 100%',
      subLabel: `${this.orderFullPrice}`,
      description:
        'Вариант для тех, кто точно определился с выбором, хочет зафиксировать стоимость круиза и заранее забронировать каюту',
    };

    const prePay = {
      value: '50',
      label: `Передоплата 50%`,
      subLabel: `${this.orderHalfPrice}`,
      description:
        'Удобно для тех, кто предпочитает разбить оплату на части и заранее забронировать каюту',
    };

    const installmentPlan = {
      value: 'installmentPlan',
      label: `В рассрочку`,
      labelTag: 'Новинка',
      description:
        'В ближайшее время с вами свяжется наш менеджер для уточнения всех деталей и подбора наилучших условий рассрочки',
    };

    const reservation = {
      value: 'reservation',
      label: 'Временное бронирование',
      description:
        'Для тех, кому нужно время на то, чтобы подумать. Сейчас вы ничего не платите. Бронь держится не более 1 суток',
    };

    return [
      (this.cardOnlineConfig?.sber_pay?.fullpay.link ||
        this.cardOnlineConfig?.tinkoff?.fullpay.link) &&
        fullPay,
      (this.cardOnlineConfig?.sber_pay?.prepay.link ||
        this.cardOnlineConfig?.tinkoff?.prepay.link) &&
        prePay,
      this.installmentPlan && installmentPlan,
      reservation,
    ];
  }

  hydrate(data) {
    if (data) {
      this.token = data.token;
      this.isMobile = data.isMobile;
    }
  }
}
