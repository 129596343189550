import React, { useState } from 'react';
import Link from 'next/link';
import classcat from 'classcat';
import { useRouter } from 'next/router';
import { Anchor } from '../../Text';
import { Button } from '../../Button';
import styles from './swithTypePage.module.sass';

export const SwithTypePage = () => {
  const router = useRouter();

  const handleReload = () => {
    router.reload();
  };

  return (
    <>
      {router.pathname === '/novogodniy-keshbek-za-pokupku-kruizov' ? (
        <div className={styles.wrapperBf}>
          <Anchor
            href="/novogodniy-keshbek-za-pokupku-kruizov"
            onClick={handleReload}
            className={classcat({
              [styles.link]: true,
              [styles.linkRiver]: true,
              [styles.linkActive]:
                (router.pathname === '/novogodniy-keshbek-za-pokupku-kruizov' &&
                  !router.query.type) ||
                router.query.type === 'rivers',
            })}
          >
            Реки России
          </Anchor>

          <Anchor
            href="/novogodniy-keshbek-za-pokupku-kruizov?type=sea"
            onClick={handleReload}
            className={classcat({
              [styles.link]: true,
              [styles.linkSea]: true,
              [styles.linkActive]: router?.query?.type === 'sea',
            })}
          >
            Морские круизы
          </Anchor>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Link href="/">
            <Anchor
              className={classcat({
                [styles.link]: true,
                [styles.linkRiver]: true,
                [styles.linkActive]: router.pathname === '/',
              })}
            >
              Реки России
            </Anchor>
          </Link>
          <Link href="/sea">
            <Anchor
              className={classcat({
                [styles.link]: true,
                [styles.linkSea]: true,
                [styles.linkActive]: router.pathname === '/sea',
              })}
            >
              Морские круизы
            </Anchor>
          </Link>
        </div>
      )}
    </>
  );
};
